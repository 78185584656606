.upload-new-div {
    margin: auto;
    text-align: center;
}

#photo {
    opacity: 0;
    /* display: none; */
    max-width: 100px;
}

#photo-preview {
    /* opacity: 0; */
    max-width: 50vw;
}

#connecting_message {
    position: fixed;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.713);
    height: 100vh;
    width: 100vw;
    z-index: 10;
}

#cannot_connect {
    position: fixed;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.713);
    height: 100vh;
    width: 100vw;
    z-index: 20;
}

#uploading_message {
    position: fixed;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.713);
    height: 100vh;
    width: 100vw;
    z-index: 20;
}

#connecting_message > h1 {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: auto;
}

#cannot_connect > div {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: auto;
}

#uploading_message > h1 {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: auto;
}

#submit-photo {
    width: max-content;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#calculate-aspect {
    width: max-content;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#submit-photo > input,
#calculate-aspect > input,
#submit-photo > select,
#calculate-aspect > select,
#submit-photo > textarea,
#calculate-aspect > textarea {
    margin: auto;
    margin-bottom: 1rem;
}

#submit-photo > textarea,
#calculate-aspect > textarea {
    width: 15rem;
    height: 5rem;
    resize: none;
}

#are_you_sure_box {
    position: fixed;
    margin: auto;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    width: max-content;
    padding: 1rem;
    background-color: white;
    border: 1px solid black;
    border-radius: 1rem;
}

#choose-item {
    margin: auto;
    margin-top: 5rem;
    width: 11rem;
    align-items: center;
}

.upload-item-button {
    width: 10rem;
    height: 3rem;
    margin: 1rem 0 1rem 0;
}

