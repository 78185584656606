@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

@keyframes fadeinandout {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bubble {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

#featured-item-page {
  margin-bottom: 15rem;
}

/* small screen */
@media (max-width: 767px) {
  #preview-full {
    display: block;
  }
  #preview-column-one {
    margin: 1vw;
    margin-bottom: 25px;
  }
  #preview-column-two {
    display: block;
    margin: 1vw;
    margin-bottom: 25px;
  }
  .dropdown {
    position: inherit;
    width: 96%;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .choose-text {
    display: block;
  }

  .button-select-options {
    height: 40px;
    margin: 15px auto 25px auto;
  }
  #placeholder {
    display: none;
  }
  .button-select-options li:first-child label {
    border-radius: 5px 0 0 5px;
  }
  
  .button-select-options li:last-child label {
    border-radius: 0 5px 5px 0;
  }
  
  #button-select-options-two li:nth-last-child(2) label {
    border-radius: 0 5px 5px 0;
  }
}

/* really small (button orientation change) */
@media (max-width: 500px) {
  .button-select-options {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 75%;
    height: 80px;
    margin: 15px auto 25px auto;
  }
  #placeholder {
    display: block;
  }
  .button-select-options li:first-child label {
    border-radius: 5px 0 0 0;
  }
  .button-select-options li:nth-last-child(3) label {
    border-radius: 0 5px 0 0;
  }
  #button-select-options-two li:nth-last-child(2) label {
    border-radius: 0 0 0 5px;
  }
  .button-select-options li:nth-last-child(2) label {
    border-radius: 0 0 0 5px;
  }
  .button-select-options li:last-child label {
    border-radius: 0 0 5px 0;
  }
}

/* large screen */
@media (min-width: 768px) {
  #preview-full {
    display: flex;
  }
  #preview-column-one {
    /* display: flex; */
    flex: 1;
    flex-direction: column;
    margin: 15px;
  }
  #preview-column-two {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 15px;
  }
  .dropdown {
    position: inherit;
    width: 96%;
    font-weight: bold;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .button-select-options {
    margin: 15px auto;
  }
  #placeholder {
    display: none;
  }
  .button-select-options li:first-child label {
    border-radius: 5px 0 0 5px;
  }
  
  .button-select-options li:last-child label {
    border-radius: 0 5px 5px 0;
  }
  
  #button-select-options-two li:nth-last-child(2) label {
    border-radius: 0 5px 5px 0;
  }
}

.add-to-cart-message {
  text-align: center;
  color: rgb(45, 150, 54);
  margin-bottom: 50px;
  font-family: "Exo 2", sans-serif;
  font-weight: 900;
  margin-right: 1vw;
  margin-left: 1vw;
}

.animate-message {
  animation-name: fadeinandout;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.no-animation {
  animation: none;
}

.hidden {
  visibility: hidden;
}

.preview {
  margin: auto;
  max-width: 1200px;
}

#preview-full {
  text-align: center;
  padding-top: 50px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  /* display: flex; */
  height: 100%;
}

.column-header {
  margin-bottom: 75px;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
}

#preview-column-one {
  text-align: center;
  max-width: 100%;
  padding: 20px;
  border: 1px solid #dadce0;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* height: 100%; */
}

#preview-column-two {
  text-align: center;
  max-width: 100%;
  padding: 20px;
  border: 1px solid #dadce0;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* height: 100%; */
}

#preview-img {
  max-height: 400px;
  max-width: 100%;
  margin-bottom: 50px;
  box-shadow: 0px 0px 30px rgb(53, 53, 53);
  pointer-events: none;
}

#description {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
}

#print-info {
  text-align: center;
  margin-bottom: 50px;
  margin-left: 35px;
  margin-right: 35px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  font-size: 12px;
  line-height: 25px;
  vertical-align: auto;
}

#basket-button,
.dropdown {
  height: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
}

#basket-button {
  margin: auto;
  position: inherit;
  margin-top: auto;
  width: 96%;
  margin-bottom: 10px;
}

#price {
  font-size: 40px;
  font-weight: 100;
  margin-bottom: 15px;
}

.basket-button {
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 5px black;
}

.basket-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.basket-button:active {
  box-shadow: inset 0px 0px 1px black;
}

.basket-button:focus {
  outline: none;
}

.error {
  box-shadow: 0px 0px 5px rgb(141, 62, 62);
  border: 1px solid rgb(197, 33, 33);
}

#early {
  font-family: "Exo 2", sans-serif;
  font-size: 65px;
  margin: auto;
  text-align: center;
  margin-top: 50px;
  font-weight: 100;
}

#save {
  font-family: "Exo 2", sans-serif;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  font-weight: 900;
}

#was {
  margin-top: 0px;
  margin-bottom: 15px;
  color:rgb(62, 141, 69);
}

/* material buttons */

.button-select-options {
  list-style-type: none;
  /* margin: 15px auto; */
  padding: 0;
  box-shadow: rgb(124, 124, 124) 0px 5px 5px -2px;
  border-radius: 5px;
  width: max-content;
}

.button-select-options li {
  float: left;
  width: 100px;
  height: 40px;
  position: relative;
  margin: 0px 0px;
}

.button-select-options label,
.button-select-options input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button-select-options input[type="radio"] {
  opacity: 0.01;
  /* z-index: 2; */
}

.button-select-options input[type="radio"]:checked+label,
.Checked+label {
  background: linear-gradient(rgb(0, 145, 255), rgb(94, 184, 252));
  box-shadow: rgba( 241, 242, 244, 100%) 0px 20px 15px 1px
}

.button-select-options label {
  padding: 10px;
  border: 1px solid #CCC;
  cursor: pointer;
  /* z-index: 1; */
  background: #FFF;
}

.button-select-options label:hover {
  background: #DDD;
}

.choose-text {
  display: block;
  margin-top: 15px;
}

#preview-product {
  width: 80%;
  margin: auto;
}

.preview-product {
  max-width: 50px;
  padding: 2px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0);
}

.preview-product:hover {
  border: 1px solid #131313;
  opacity: .5;
  cursor: pointer;
}

.preview-image {
  max-width: 100px;
  padding: 2px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0);
}

.preview-image:hover {
  border: 1px solid #131313;
  opacity: .5;
  cursor: pointer;
}

#preview-images {
  display: grid;
  width: 80%;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1200px) {
  #preview-images {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  @media (min-width: 768px) {
    #preview-images {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 475px) {
  #preview-images {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 350px) {
  #preview-images {
    grid-template-columns: 1fr;
  }
}

/* savings shape */
#savings-main-shape-relative {
  position: relative;
  width: 0px;
  height: 0px;
  /* margin: 0px 0px 0px 85%; */
  margin-left: 100%;
  left: -95.5px;
  bottom: -12px;
  /* transform: rotate(45deg); */
  /* z-index: 10; */
  /* outline: 1px solid black; */
}

#savings-main-shape-absolute {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 0px;
  bottom: 0px;
  background-color: #F1A43A;
  /* border: 1px solid black; */
  animation-name: bubble;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  /* animation-fill-mode: backwards; */
}

#savings-main-shape-text {
  position: relative;
  margin: auto;
  margin-top: 15px;
}

#savings-second-shape-relative {
  position: relative;
  width: 0px;
  height: 0px;
  /* margin: 0px 0px 0px 85%; */
  margin-left: 100%;
  left: -115px;
  bottom: 33px;
  transform: rotate(45deg);
  /* z-index: 10; */
  /* outline: 1px solid black; */
}

#savings-second-shape-absolute {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 0px;
  bottom: 0px;
  background-color: #F1A43A;
  border: 1px solid black;
  animation-name: bubble;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  /* animation-fill-mode: backwards; */
}