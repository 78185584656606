.landing-page {
  display: block;
  position: relative;
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 100vw;
  height: 100%;
  cursor: default;
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/alec-hovey-photography.appspot.com/o/images%2FYosemite%20Winter.jpg?alt=media&token=f919d7d9-17e5-435c-878d-d6bf4c0a1557); */
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .landing-page::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: red;
} */

.space-around-text {
  background-color: rgba(255, 255, 255, 0.255);
  width: 100vw;
  height: 100vh;
  padding-top: 35px;
}

#landing-link {
  text-decoration: none;
  color: black;
}

.anywhere {
  margin-top: 35px;
}

#landing-page-info-box {
  text-align: center;
  position: relative;
  top: 10vh;
  margin: auto;
  width: 560px;
  max-width: 80vw;
  padding: 20px;
  background-color: white;
  border: 1px solid #dadce0;
  font-family: "Exo 2", sans-serif;
  border-radius: 5px;
  /* box-shadow: rgb(100, 100, 100) 5px 5px 10px 1px; */
}

#landing-page-info-box h2 {
  font-size: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: rgb(100, 100, 100) 5px 5px 10px 1px;
  border-radius: 10px;
  background-image: linear-gradient(rgb(143, 179, 255), rgb(109, 143, 255));
}

#landing-page-info-box p {
  font-size: 20px;
  line-height: 35px;
}
