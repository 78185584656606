#logged_email {
    text-align: end;
    padding: 1rem;
}

#logged_email > button {
    margin-left: 1rem;
}

#email_admin {
    display: inline-block;
}

#upload_info_body {
    margin: auto;
    margin-top: 5rem;
    text-align: center;
}

#upload_info_options {
    margin-top: 2rem;
    display: block;
}

#upload_info_options > .option_buttons {
    margin: auto;
    margin-top: 2rem;
    display: block;
}

.option_buttons {
    width: max-content;
}

.button-actual {
    padding: 1rem 2rem 1rem 2rem;
    background-color: lightcoral;
    border-radius: 0.5rem;
    font-size: 1.25rem;
}

.button-actual:hover {
    background-color: coral;
}

.button-actual:focus {
    background-color: cornflowerblue;
}