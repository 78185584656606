@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

@keyframes fadeout {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

/* small screen */
@media (max-width: 767px) {
  .checkoutProduct {
    display: block;
    /* justify-content: center; */
  }

  .checkoutProduct-image {
    display: block;
    margin: auto;
    margin-bottom: 5px;
  }

  .checkoutProduct-info {
    text-align: center;
  }

  .checkoutProduct-info > button {
    margin-bottom: 10px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .all-products {
    max-width: 500px;
  }

  .checkoutProduct {
    display: flex;
    justify-content: left;
  }

  .checkoutProduct-info {
    text-align: center;
    flex: 1;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.checkoutProduct {
  margin: 15px;
  font-family: "Exo 2", sans-serif;
  /* animation-name: fadeout;
  animation-duration: 1.5s;
  animation-fill-mode: backwards; */
}

.checkoutProduct-title {
  font-size: 20px;
  font-family: "Cinzel Decorative", cursive;
  padding-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 15px;
  /* border-bottom: 1px solid #d3d3d3; */
}

.checkoutProduct-size {
  text-align: center;
  font-size: 20px;
  font-weight: 1000;
  margin-bottom: 5px;
}

.checkoutProduct-price {
  text-align: center;
  font-size: 15px;
  font-weight: 1000;
  margin-bottom: 15px;
}

.checkoutProduct-image {
  object-fit: contain;
  width: 180px;
  height: 180px;
  pointer-events: none;
}

.checkoutProduct-info > button {
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  outline: none;
}

.checkoutProduct-info > button:hover {
  box-shadow: 0px 0px 5px rgb(197, 33, 33);
  border: 1px solid rgb(197, 33, 33);
  cursor: pointer;
  outline: none;
}

.checkoutProduct-info > button:active {
  box-shadow: inset 0px 0px 1px black;
}

.checkoutProduct-info > button:focus {
  outline: none;
}
