#home {
  width: 100vw;
}

#featured-title {
  font-family: "Satisfy", cursive;
  font-size: min(12vw, 60px);
  text-align: center;
  margin-top: 5rem;
}
