@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

/* @media (max-width: 1500px) {
    #event-package-one {
        flex: 1;
        flex-direction: column;
        margin: 15px;
        max-width: 500px;
      }
} */

#event-packages-component {
  font-family: "Exo 2", sans-serif;
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

#event-packages-header {
  font-family: "Satisfy", cursive;
  margin-top: 30px;
  margin-bottom: 20px;
}

#event-packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

#event-package-one {
  flex: 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#event-package-two {
  flex: 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#event-package-three {
  flex: 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#event-package-one > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#event-package-two > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#event-package-three > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

.solo-package-list {
  list-style: square inside;
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: 500px;
  max-width: 500px;
  min-width: 200px;
}

.solo-package-list > li {
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-button {
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 5px rgb(74, 74, 74);
  background-color: white;
  border-radius: 5px;
  margin: auto;
  position: inherit;
  margin-top: auto;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.select-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.select-button:active {
  box-shadow: inset 0px 0px 1px black;
}

.select-button:focus {
  outline: none;
}

#overview-link-event {
  margin-top: 35px;
  margin-bottom: 30px;
}

#portrait-request-form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.277);
}

#main-form-div {
  position: relative;
  display: block;
  margin: auto;
  max-width: 400px;
  background: transparent;
  /* border: 1px solid black; */
}

#modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}

#portrait-packages-description {
  font-family: "Exo 2", sans-serif;
  font-size: 1em;
}
