@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

/* small screen */
@media (max-width: 767px) {
  .subtotal {
    margin: auto;
    margin-top: 50px;
    width: inherit;
    max-width: 400px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .subtotal {
    display: flex;
    width: 300px;
  }
}

.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.subtotal-gift {
  display: flex;
  align-items: center;
}

.subtotal-gift > input {
  margin-right: 5px;
}

.subtotal > button {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(110, 110, 110);
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px black;
}

.subtotal > button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.subtotal > button:active {
  box-shadow: inset 0px 0px 1px black;
}

.subtotal > button:focus {
  outline: none;
}

#contract {
  text-decoration: none;
}

#contract:visited {
  color: gray;
}
