@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

/* small screen */
@media (max-width: 767px) {
  #checkout-right {
    margin: 20px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .checkout {
    display: flex;
  }
}

.checkout {
  margin: auto;
  padding: 20px;
  height: max-content;
  max-width: 1200px;
}

#checkout-title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

#empty-cart-title {
  margin-right: 10px;
  padding: 10px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;
}

#checkout-left {
  width: 100%;
}
