@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

/* small screen */
@media (max-width: 425px) {
  /* #names {
    display: block;
    margin: auto;
    width: 90%;
  } */

  #first-name {
    margin-bottom: 20px;
  }

  #first-name-input {
    max-width: 90%;
  }

  #last-name-input {
    max-width: 90%;
  }

  #comments {
    display: block;
    text-align: center;
  }
}

/* large screen */
@media (min-width: 426px) {
  #names {
    display: flex;
    text-align: left;
    justify-content: space-between;
    width: 90%;
    padding: 5px;
    margin: auto;
    margin-left: 15px;
    /* border: 1px solid black; */
  }

  #first-name-input {
    margin-left: 19px;
    width: 90%;
  }

  #last-name-input {
    margin-right: 15px;
    width: 90%;
  }

  #email {
    text-align: left;
    /* border: 1px solid black; */
  }

  #email > label,
  #comments > label,
  #required-fname,
  #required-email,
  #required-comments {
    margin-left: 15px;
  }

  #first-name > label {
    margin-left: 19px;
  }

  #comments {
    display: block;
    text-align: left;
    /* border: 1px solid black; */
  }
}

#names {
  margin: 10px;
}

#first-name > input {
  /* border: 1px solid rgb(110, 110, 110); */
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* width: 90%; */
}

#first-name > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#last-name > input {
  /* border: 1px solid rgb(110, 110, 110); */
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* width: 90%; */
}

#last-name > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#contact-form > input {
  font-size: 16px;
}

#contact-form > #email {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#contact-form > #first-name {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#contact-form > #last-name {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#contact-form > #comments {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#email > input {
  /* border: 1px solid rgb(110, 110, 110); */
  display: block;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  width: 90%;
}

#email > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#comments > textarea {
  /* border: 1px solid rgb(110, 110, 110); */
  display: block;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  width: 90%;
}

#comments > textarea:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#contact {
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  text-align: center;
  margin: 75px auto 0 auto;
  height: 100%;
  max-width: 800px;
  margin-bottom: 15rem;
}

#contact > h1,
h2 {
  font-weight: 100;
}

#contact > p {
  margin: 40px;
}

#contact-form-div {
  margin: 25px;
  margin-top: 50px;
  margin-bottom: 5px;
}

.submit-popup {
  margin-bottom: 90px;
  color: rgb(45, 150, 54);
}

#contact-form {
  display: block;
  max-width: 400px;
  margin: auto;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  border-radius: 5px;
}

#submit {
  max-width: 75px;
  margin: auto;
  margin-bottom: 20px;
}

#submit > button {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(110, 110, 110);
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

#submit > button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

#submit > button:active {
  box-shadow: inset 0px 0px 1px black;
}

#submit > button:focus {
  outline: none;
}

#contact-form > #comments > textarea {
  margin-top: 10px;
  height: 100px;
  resize: none;
}

.styleOnEnter {
  box-shadow: 0px 0px 0px black;
  border: 1px solid rgb(110, 110, 110);
}

.styleBeforeEnter {
  box-shadow: 0px 0px 5px rgb(66, 66, 66);
  border: 1px solid rgb(110, 110, 110);
}

.styleOnError {
  box-shadow: 0px 0px 5px rgb(204, 92, 92);
  border: 1px solid rgb(204, 92, 92);
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

#required {
  color: rgb(204, 92, 92);
}

#contact-me-title {
  font-family: "Satisfy", cursive;
  font-size: min(12vw, 60px);
  text-align: center;
}

.recaptcha-contact {
  width: max-content;
  margin: auto;
}
