@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 767px) {
}

/* @media (max-width: 500px) {
  .photo {
    max-width: 80vw;
  }
}

@media (min-width: 501px) {
  .photo {
    max-width: 80vw;
  }
} */

@media (min-width: 768px) {
}

#photos {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 90vw;
  justify-content: center;
}

.photo {
  padding: 2px;
  max-height: 400px;
  max-width: 75vw;
  display: block;
  /* opacity: 100%; */
  border-radius: 0px;
  pointer-events: none;
}

.photo-click {
  cursor: pointer;
  margin: 10px;
  max-width: 80vw;
}

/* border colors */

#photo-one {
  /* opacity: 100%; */
  animation-name: fadein;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-timing-function: cubic-bezier(0.25, 0, 0.25, 1);
  animation-fill-mode: backwards;
}
