h1 {
    text-align: center;
}

#login {
    width: max-content;
    margin: auto;
    margin-top: 5rem;
}

form > input {
    display: block;
    margin-bottom: 1rem;
}

form > label {
    margin-top: 2rem;
}

form > button {
    text-align: center;
    width: 100%;
}