@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

/* small screen */
@media (max-width: 767px) {
  .item-description {
    max-width: 300px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .item-description {
    max-width: 500px;
  }
}

.order {
  font-family: "Exo 2", sans-serif;
  max-width: 1200px;
  margin: auto;
}

.order * {
  text-align: center;
}

.item-description {
  border: 1px solid #dadce0;
  background-color: #f3f3f3;
  margin-left: 15px;
  margin-right: 15px;
  margin: auto;
}

.order-title {
  margin-top: 35px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dadce0;
  margin-right: auto;
  margin-left: auto;
  width: 75px;
}

.order-id {
  margin-bottom: 25px;
}

.order-total {
  padding: 5px;
  border-top: 1px solid #dadce0;
  margin: auto;
}
