@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

/* small screen */
@media (max-width: 767px) {
  .payment {
    display: block;
  }
}

/* large screen */
@media (min-width: 768px) {
  .payment {
    display: flex;
  }
}

.payment {
  margin: auto;
  font-family: "Exo 2", sans-serif;
  max-width: 1200px;
}

.payment-container {
  flex: 1;
}

.payment-priceContainer {
  margin: auto;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgray;
}

.payment-couponContainer {
  margin: auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgray;
}

#pay-now-button {
  font-family: "Exo 2", sans-serif;
  margin-top: 15px;
  margin-left: 0px;
}

.not-active-button {
  border: 1px solid rgb(171, 171, 171);
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 15px;
  outline: none;
}

.not-active-button:hover {
  cursor: pointer;
  outline: none;
}

/* #pay-now-button:active {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
} */

.not-active-button:focus {
  outline: none;
}

.active-button {
  border: 1px solid black;
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 15px;
  outline: none;
}

.active-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  border: 1px solid rgb(62, 141, 69);
  font-weight: 1000;
  cursor: pointer;
  outline: none;
}

.active-button:active {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
}

.coupon-button {
  border: 1px solid black;
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  /* margin-left: 15px;
  margin-right: 15px; */
  font-size: 15px;
  outline: none;
}

.coupon-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  border: 1px solid rgb(62, 141, 69);
  font-weight: 1000;
  cursor: pointer;
  outline: none;
}

.coupon-button:active {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
}

/* end of button styling */

.payment-fullPage > h1 {
  text-align: center;
  background-image: radial-gradient(circle, #f1f3f4, white);
  padding: 10px;
  margin: 0 20px;
  font-weight: 100;
  border-bottom: 1px solid lightgray;
}

.payment-fullPage > h1 > a {
  text-decoration: none;
}

.payment-section {
  display: block;
  padding: 20px;
  margin: 25px 20px;
  background-color: #f1f3f4;
  border: 1px solid lightgray;
}

.shipping-method-section {
  position: relative;
  display: block;
  padding: 20px;
  margin: 25px 20px;
  background-color: #f1f3f4;
  border: 1px solid lightgray;
}

/* .payment-title {
  flex: 0.4;
} */

.payment-items {
  border: 1px solid lightgray;
  padding: 10px;
  margin: 20px auto;
  background-color: white;
}

.payment-email {
  margin: 15px 15px;
}

.payment-email-input,
.shipping-details-input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgray;
  background-color: white;
  padding: 5px;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  color: black;
  outline: none;
}

.shipping-details-input {
  margin: 5px;
}

.payment-details {
  border: 1px solid lightgray;
  box-sizing: border-box;
  padding: 5px;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  margin: 20px 15px;
  background-color: white;
}

.payment-card,
.shipping-details-full {
  display: flex;
  flex-wrap: wrap;
  font-family: "Exo 2", sans-serif;
  margin: 10px 10px;
}

.payment-card-input {
  border: 1px solid lightgray;
  font-family: "Exo 2", sans-serif;
  box-sizing: border-box;
  background-color: white;
  margin: 5px;
  padding: 5px;
  justify-content: space-between;
}

.coupon-code-input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid lightgray;
  background-color: white;
  padding: 5px;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  color: black;
  outline: none;
}

#card-number {
  width: 213px;
  /* flex: 6; */
}

#card-expiry {
  width: 91px;
  /* flex: 1; */
}

#card-cvc {
  width: 55px;
  /* flex: 1; */
}

.check-box {
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
  -moz-appearance: none;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #d3d3d3;
  outline: none;
  background-color: white;
}

.check-box:checked {
  border: 1px solid white;
  background-color: #60b5ee;
}

.label {
  font-size: 20px;
  padding-left: 10px;
  padding-bottom: auto;
}

.checkbox-container {
  display: block;
  padding-right: 15px;
  padding-left: 35px;
  text-indent: -30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hidden {
  visibility: hidden;
}

#error-message {
  color: #d73733;
  margin: 0px 15px 15px 15px;
  font-size: 15px;
}

.all-inputs {
  display: inline-flex;
  width: 100%;
}

.all-crosses {
  margin: auto;
  margin-left: 10px;
  max-width: 20px;
  max-height: 20px;
}

.all-crosses > img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.processing {
  font-weight: 1000;
}

h3 {
  text-align: left;
  margin-top: 0px;
}

/* material buttons */

.button-select-options-payment {
  list-style-type: none;
  margin: 15px auto;
  padding: 0;
  box-shadow: rgb(124, 124, 124) 0px 5px 5px -2px;
  border-radius: 5px;
  width: max-content;
  height: 40px;
  /* outline: 2px solid black; */
}

.button-select-options-payment li {
  float: left;
  width: 100px;
  height: 40px;
  position: relative;
  margin: 0px 0px;
}

.button-select-options-payment label,
.button-select-options-payment input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* box-shadow: rgb(124, 124, 124) 0px 5px 5px -2px; */
}

.button-select-options-payment input[type="radio"] {
  opacity: 0.01;
  z-index: 2;
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.button-select-options-payment input[type="radio"]:hover+label {
  background: #DDD;
}

.button-select-options-payment input[type="radio"]:checked+label,
.Checked+label {
  background: linear-gradient(rgb(0, 145, 255), rgb(94, 184, 252));
  box-shadow: rgba( 241, 242, 244, 100%) 0px 20px 15px 1px
}

.button-select-options-payment label {
  padding: 10px;
  text-align: center;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 1;
  background: #FFF;
}

@media (max-width: 375px) {
  .button-select-options-payment {
    display: grid;
    grid-template-columns: 100%;
    /* row-gap: 5px; */
    width: 100px;
    height: 120px;
    margin: 15px auto 25px auto;
  }
  .button-select-options-payment label,
  .button-select-options-payment input:first-child {
    border-radius: 5px 5px 5px 5px;
  }

}

@media (min-width: 376px) {
  .button-select-options-payment li:first-child label {
    border-radius: 5px 0 0 5px;
  }

  .button-select-options-payment li:last-child label {
    border-radius: 0 5px 5px 0;
  }
}

/* SHIPPING OPTIONS COVERS */

.no-shipping-required-cover {
  text-align: center;
  z-index: 5;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  /* top: inherit;
  left: inherit; */
  /* padding: 20px; */
  /* background-color: rgba(0, 0, 0, 0.535); */
  background-color: #f1f3f48c;
}

.no-shipping-required-cover p {
  color: black;
  margin: 0;
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 0.5rem;
  /* transform: translate(-50%, -50%); */
  /* background-color: rgba(0, 0, 0, 0.337); */
  /* border: 1px solid black; */
  border-radius: 0.5rem;
}
