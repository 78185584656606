@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

@keyframes colorchange {
  from {
    color: #2d2e2e;
  }
  to {
    color: #6e6e6e;
  }
}

/* extra small screen */
@media (max-width: 420px) {
  #pages {
    visibility: hidden;
  }
}

/* small screen */
@media (max-width: 767px) {
  .nav-link {
    font-size: 12px;
  }

  #nav-links {
    width: 100%;
    display: flex;
    padding: 0px;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #nav-links-fixed {
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #header-div {
    background: linear-gradient(#474747, #e2e2e200);
    height: 15px;
    width: 100%;
  }
}

@media (min-width: 421px) {
  #nav-dropdown {
    visibility: hidden;
  }
}

/* large screen */
@media (min-width: 768px) {
  .nav-link {
    font-size: 18px;
  }

  #nav-links {
    width: 100%;
    display: flex;
    padding: 0px;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #nav-links-fixed {
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #header-div {
    background: linear-gradient(#474747, #e2e2e200);
    height: 15px;
    width: 100%;
  }
}

#name-text-home {
  font-family: "Cinzel Decorative", cursive;
  margin-top: 25px;
  font-size: calc((6vw + 6vh) / 2);
}

#photo-text-home {
  font-family: "Exo 2", sans-serif;
  margin-bottom: 0px;
  font-size: calc((3.5vw + 3.5vh) / 2);
}

#nav {
  display: block;
  text-align: center;
  opacity: 1;
  top: 0px;
  width: 100vw;
  /* padding-bottom: 5px; */
  background: radial-gradient(rgba(255, 187, 132, 0.11), white);
}

#nav-text {
  text-align: center;
  padding: 20px 20px;
}

#home-link {
  color: #2d2e2e;
  text-decoration: none;
  padding: 3px;
}

.nav-link {
  color: #131313;
  padding-right: 2vw;
  padding-left: 2vw;
  text-decoration: none;
  transition: color 0.75s;
}

.nav-link:hover {
  color: #525252 !important;
}

.clicked-link {
  color: #525252;
}

#nav-active {
  color: #3b3b3b;
  padding-right: 2vw;
  padding-left: 2vw;
  text-decoration: none;
  transition: color 0.75s;
}

li {
  display: inline;
}

#nav-links-group,
#nav-links-fixed-group {
  display: block;
  width: 100vw;
  overflow: hidden;
}

.make-invisible {
  visibility: hidden;
}

.make-visible {
  visibility: visible;
}

ul {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
  padding: 10px 0px;
  list-style-type: none;
}

#header {
  background-color: rgb(247, 247, 247);
  position: relative;
  height: 100%;
  width: 100vw;
}

#cart {
  position: absolute;
  right: 10px;
}

#menu-button {
  position: absolute;
  left: 20px;
  padding: 0px;
  /* padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px; */
  margin: 0px;
  height: 33px;
  margin-top: -6px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  border-color: rgba(255, 255, 255, 0);
  z-index: 2;
}

#menu-button:active,
#menu-button:focus {
  outline: none;
}

#side-menu {
  position: absolute;
  left: 0px;
  z-index: 1;
}

#side-menu-fixed {
  position: fixed;
  left: 0px;
  top: 0;
}

#side-menu,
#side-menu-fixed {
  display: block;
  border: 1px solid #a0a0a0;
  border-top: none;
  /* border-left: none; */
  max-width: 100%;
  min-width: 200px;
  height: 255px;
  background: linear-gradient(#ffe1c8, #ffd8ab);
}

#side-menu > ul,
#side-menu-fixed > ul {
  list-style-type: none;
  line-height: 50px;
  padding-left: 28px;
  padding-top: 40px;
}

#side-menu > li,
#side-menu-fixed > li {
  display: list-item;
}

.side-menu-link {
  text-decoration: none;
  color: #131313;
}

.side-menu-link:hover {
  color: #525252 !important;
}

.side-menu-link:active {
  color: #525252;
}
