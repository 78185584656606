@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Satisfy&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
}

body {
  background-color: rgb(255, 255, 255);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes colorchange {
  from {
    color: #2d2e2e;
  }
  to {
    color: #6e6e6e;
  }
}

/* extra small screen */
@media (max-width: 420px) {
  #pages {
    visibility: hidden;
  }
}

/* small screen */
@media (max-width: 767px) {
  .nav-link {
    font-size: 12px;
  }

  #nav-links {
    width: 100%;
    display: flex;
    padding: 0px;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #nav-links-fixed {
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #header-div {
    background: linear-gradient(#474747, #e2e2e200);
    height: 15px;
    width: 100%;
  }
}

@media (min-width: 421px) {
  #nav-dropdown {
    visibility: hidden;
  }
}

/* large screen */
@media (min-width: 768px) {
  .nav-link {
    font-size: 18px;
  }

  #nav-links {
    width: 100%;
    display: flex;
    padding: 0px;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #nav-links-fixed {
    position: fixed;
    top: 0;
    z-index: 1;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #474747;
    background: linear-gradient(#ffe1c8, #ffd8ab);
    background-clip: border-box;
  }

  #header-div {
    background: linear-gradient(#474747, #e2e2e200);
    height: 15px;
    width: 100%;
  }
}

#name-text-home {
  font-family: "Cinzel Decorative", cursive;
  margin-top: 25px;
  font-size: calc((6vw + 6vh) / 2);
}

#photo-text-home {
  font-family: "Exo 2", sans-serif;
  margin-bottom: 0px;
  font-size: calc((3.5vw + 3.5vh) / 2);
}

#nav {
  display: block;
  text-align: center;
  opacity: 1;
  top: 0px;
  width: 100vw;
  /* padding-bottom: 5px; */
  background: radial-gradient(rgba(255, 187, 132, 0.11), white);
}

#nav-text {
  text-align: center;
  padding: 20px 20px;
}

#home-link {
  color: #2d2e2e;
  text-decoration: none;
  padding: 3px;
}

.nav-link {
  color: #131313;
  padding-right: 2vw;
  padding-left: 2vw;
  text-decoration: none;
  transition: color 0.75s;
}

.nav-link:hover {
  color: #525252 !important;
}

.clicked-link {
  color: #525252;
}

#nav-active {
  color: #3b3b3b;
  padding-right: 2vw;
  padding-left: 2vw;
  text-decoration: none;
  transition: color 0.75s;
}

li {
  display: inline;
}

#nav-links-group,
#nav-links-fixed-group {
  display: block;
  width: 100vw;
  overflow: hidden;
}

.make-invisible {
  visibility: hidden;
}

.make-visible {
  visibility: visible;
}

ul {
  font-family: "Exo 2", sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
  padding: 10px 0px;
  list-style-type: none;
}

#header {
  background-color: rgb(247, 247, 247);
  position: relative;
  height: 100%;
  width: 100vw;
}

#cart {
  position: absolute;
  right: 10px;
}

#menu-button {
  position: absolute;
  left: 20px;
  padding: 0px;
  /* padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px; */
  margin: 0px;
  height: 33px;
  margin-top: -6px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  border-color: rgba(255, 255, 255, 0);
  z-index: 2;
}

#menu-button:active,
#menu-button:focus {
  outline: none;
}

#side-menu {
  position: absolute;
  left: 0px;
  z-index: 1;
}

#side-menu-fixed {
  position: fixed;
  left: 0px;
  top: 0;
}

#side-menu,
#side-menu-fixed {
  display: block;
  border: 1px solid #a0a0a0;
  border-top: none;
  /* border-left: none; */
  max-width: 100%;
  min-width: 200px;
  height: 255px;
  background: linear-gradient(#ffe1c8, #ffd8ab);
}

#side-menu > ul,
#side-menu-fixed > ul {
  list-style-type: none;
  line-height: 50px;
  padding-left: 28px;
  padding-top: 40px;
}

#side-menu > li,
#side-menu-fixed > li {
  display: list-item;
}

.side-menu-link {
  text-decoration: none;
  color: #131313;
}

.side-menu-link:hover {
  color: #525252 !important;
}

.side-menu-link:active {
  color: #525252;
}

@keyframes fadeinandout {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes bubble {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.75);
  }
}

#featured-item-page {
  margin-bottom: 15rem;
}

/* small screen */
@media (max-width: 767px) {
  #preview-full {
    display: block;
  }
  #preview-column-one {
    margin: 1vw;
    margin-bottom: 25px;
  }
  #preview-column-two {
    display: block;
    margin: 1vw;
    margin-bottom: 25px;
  }
  .dropdown {
    position: inherit;
    width: 96%;
    font-weight: bold;
    margin-top: 15px;
    margin-bottom: 25px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .choose-text {
    display: block;
  }

  .button-select-options {
    height: 40px;
    margin: 15px auto 25px auto;
  }
  #placeholder {
    display: none;
  }
  .button-select-options li:first-child label {
    border-radius: 5px 0 0 5px;
  }
  
  .button-select-options li:last-child label {
    border-radius: 0 5px 5px 0;
  }
  
  #button-select-options-two li:nth-last-child(2) label {
    border-radius: 0 5px 5px 0;
  }
}

/* really small (button orientation change) */
@media (max-width: 500px) {
  .button-select-options {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 75%;
    height: 80px;
    margin: 15px auto 25px auto;
  }
  #placeholder {
    display: block;
  }
  .button-select-options li:first-child label {
    border-radius: 5px 0 0 0;
  }
  .button-select-options li:nth-last-child(3) label {
    border-radius: 0 5px 0 0;
  }
  #button-select-options-two li:nth-last-child(2) label {
    border-radius: 0 0 0 5px;
  }
  .button-select-options li:nth-last-child(2) label {
    border-radius: 0 0 0 5px;
  }
  .button-select-options li:last-child label {
    border-radius: 0 0 5px 0;
  }
}

/* large screen */
@media (min-width: 768px) {
  #preview-full {
    display: flex;
  }
  #preview-column-one {
    /* display: flex; */
    flex: 1 1;
    flex-direction: column;
    margin: 15px;
  }
  #preview-column-two {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    margin: 15px;
  }
  .dropdown {
    position: inherit;
    width: 96%;
    font-weight: bold;
    margin: auto;
    margin-top: 25px;
    margin-bottom: 10px;
  }
  .button-select-options {
    margin: 15px auto;
  }
  #placeholder {
    display: none;
  }
  .button-select-options li:first-child label {
    border-radius: 5px 0 0 5px;
  }
  
  .button-select-options li:last-child label {
    border-radius: 0 5px 5px 0;
  }
  
  #button-select-options-two li:nth-last-child(2) label {
    border-radius: 0 5px 5px 0;
  }
}

.add-to-cart-message {
  text-align: center;
  color: rgb(45, 150, 54);
  margin-bottom: 50px;
  font-family: "Exo 2", sans-serif;
  font-weight: 900;
  margin-right: 1vw;
  margin-left: 1vw;
}

.animate-message {
  animation-name: fadeinandout;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

.no-animation {
  animation: none;
}

.hidden {
  visibility: hidden;
}

.preview {
  margin: auto;
  max-width: 1200px;
}

#preview-full {
  text-align: center;
  padding-top: 50px;
  margin-top: 50px;
  margin-left: 20px;
  margin-right: 20px;
  /* display: flex; */
  height: 100%;
}

.column-header {
  margin-bottom: 75px;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
}

#preview-column-one {
  text-align: center;
  max-width: 100%;
  padding: 20px;
  border: 1px solid #dadce0;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* height: 100%; */
}

#preview-column-two {
  text-align: center;
  max-width: 100%;
  padding: 20px;
  border: 1px solid #dadce0;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* height: 100%; */
}

#preview-img {
  max-height: 400px;
  max-width: 100%;
  margin-bottom: 50px;
  box-shadow: 0px 0px 30px rgb(53, 53, 53);
  pointer-events: none;
}

#description {
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
}

#print-info {
  text-align: center;
  margin-bottom: 50px;
  margin-left: 35px;
  margin-right: 35px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  font-size: 12px;
  line-height: 25px;
  vertical-align: auto;
}

#basket-button,
.dropdown {
  height: 30px;
  background-color: #ffffff;
  border-radius: 5px;
  outline: none;
}

#basket-button {
  margin: auto;
  position: inherit;
  margin-top: auto;
  width: 96%;
  margin-bottom: 10px;
}

#price {
  font-size: 40px;
  font-weight: 100;
  margin-bottom: 15px;
}

.basket-button {
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 5px black;
}

.basket-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.basket-button:active {
  box-shadow: inset 0px 0px 1px black;
}

.basket-button:focus {
  outline: none;
}

.error {
  box-shadow: 0px 0px 5px rgb(141, 62, 62);
  border: 1px solid rgb(197, 33, 33);
}

#early {
  font-family: "Exo 2", sans-serif;
  font-size: 65px;
  margin: auto;
  text-align: center;
  margin-top: 50px;
  font-weight: 100;
}

#save {
  font-family: "Exo 2", sans-serif;
  margin: auto;
  text-align: center;
  margin-top: 20px;
  font-weight: 900;
}

#was {
  margin-top: 0px;
  margin-bottom: 15px;
  color:rgb(62, 141, 69);
}

/* material buttons */

.button-select-options {
  list-style-type: none;
  /* margin: 15px auto; */
  padding: 0;
  box-shadow: rgb(124, 124, 124) 0px 5px 5px -2px;
  border-radius: 5px;
  width: -webkit-max-content;
  width: max-content;
}

.button-select-options li {
  float: left;
  width: 100px;
  height: 40px;
  position: relative;
  margin: 0px 0px;
}

.button-select-options label,
.button-select-options input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.button-select-options input[type="radio"] {
  opacity: 0.01;
  /* z-index: 2; */
}

.button-select-options input[type="radio"]:checked+label,
.Checked+label {
  background: linear-gradient(rgb(0, 145, 255), rgb(94, 184, 252));
  box-shadow: rgba( 241, 242, 244, 100%) 0px 20px 15px 1px
}

.button-select-options label {
  padding: 10px;
  border: 1px solid #CCC;
  cursor: pointer;
  /* z-index: 1; */
  background: #FFF;
}

.button-select-options label:hover {
  background: #DDD;
}

.choose-text {
  display: block;
  margin-top: 15px;
}

#preview-product {
  width: 80%;
  margin: auto;
}

.preview-product {
  max-width: 50px;
  padding: 2px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0);
}

.preview-product:hover {
  border: 1px solid #131313;
  opacity: .5;
  cursor: pointer;
}

.preview-image {
  max-width: 100px;
  padding: 2px;
  margin: 0 auto;
  border: 1px solid rgba(0, 0, 0, 0);
}

.preview-image:hover {
  border: 1px solid #131313;
  opacity: .5;
  cursor: pointer;
}

#preview-images {
  display: grid;
  width: 80%;
  margin: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1200px) {
  #preview-images {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  @media (min-width: 768px) {
    #preview-images {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 475px) {
  #preview-images {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 350px) {
  #preview-images {
    grid-template-columns: 1fr;
  }
}

/* savings shape */
#savings-main-shape-relative {
  position: relative;
  width: 0px;
  height: 0px;
  /* margin: 0px 0px 0px 85%; */
  margin-left: 100%;
  left: -95.5px;
  bottom: -12px;
  /* transform: rotate(45deg); */
  /* z-index: 10; */
  /* outline: 1px solid black; */
}

#savings-main-shape-absolute {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 0px;
  bottom: 0px;
  background-color: #F1A43A;
  /* border: 1px solid black; */
  animation-name: bubble;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  /* animation-fill-mode: backwards; */
}

#savings-main-shape-text {
  position: relative;
  margin: auto;
  margin-top: 15px;
}

#savings-second-shape-relative {
  position: relative;
  width: 0px;
  height: 0px;
  /* margin: 0px 0px 0px 85%; */
  margin-left: 100%;
  left: -115px;
  bottom: 33px;
  transform: rotate(45deg);
  /* z-index: 10; */
  /* outline: 1px solid black; */
}

#savings-second-shape-absolute {
  position: absolute;
  width: 90px;
  height: 90px;
  left: 0px;
  bottom: 0px;
  background-color: #F1A43A;
  border: 1px solid black;
  animation-name: bubble;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  /* animation-fill-mode: backwards; */
}
#home {
  width: 100vw;
}

#featured-title {
  font-family: "Satisfy", cursive;
  font-size: min(12vw, 60px);
  text-align: center;
  margin-top: 5rem;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 767px) {
}

/* @media (max-width: 500px) {
  .photo {
    max-width: 80vw;
  }
}

@media (min-width: 501px) {
  .photo {
    max-width: 80vw;
  }
} */

@media (min-width: 768px) {
}

#photos {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 90vw;
  justify-content: center;
}

.photo {
  padding: 2px;
  max-height: 400px;
  max-width: 75vw;
  display: block;
  /* opacity: 100%; */
  border-radius: 0px;
  pointer-events: none;
}

.photo-click {
  cursor: pointer;
  margin: 10px;
  max-width: 80vw;
}

/* border colors */

#photo-one {
  /* opacity: 100%; */
  animation-name: fadein;
  animation-duration: 1.5s;
  animation-delay: 0.5s;
  animation-timing-function: cubic-bezier(0.25, 0, 0.25, 1);
  animation-fill-mode: backwards;
}

/* small screen */
@media (max-width: 767px) {
  #checkout-right {
    margin: 20px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .checkout {
    display: flex;
  }
}

.checkout {
  margin: auto;
  padding: 20px;
  height: -webkit-max-content;
  height: max-content;
  max-width: 1200px;
}

#checkout-title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
  font-family: "Exo 2", sans-serif;
  font-weight: 500;
  margin-bottom: 20px;
}

#empty-cart-title {
  margin-right: 10px;
  padding: 10px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  font-size: 35px;
  margin-bottom: 20px;
  text-align: center;
}

#checkout-left {
  width: 100%;
}

/* small screen */
@media (max-width: 767px) {
  .subtotal {
    margin: auto;
    margin-top: 50px;
    width: inherit;
    max-width: 400px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .subtotal {
    display: flex;
    width: 300px;
  }
}

.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 125px;
  padding: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.subtotal-gift {
  display: flex;
  align-items: center;
}

.subtotal-gift > input {
  margin-right: 5px;
}

.subtotal > button {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(110, 110, 110);
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0px 0px 5px black;
}

.subtotal > button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.subtotal > button:active {
  box-shadow: inset 0px 0px 1px black;
}

.subtotal > button:focus {
  outline: none;
}

#contract {
  text-decoration: none;
}

#contract:visited {
  color: gray;
}

@keyframes fadeout {
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

/* small screen */
@media (max-width: 767px) {
  .checkoutProduct {
    display: block;
    /* justify-content: center; */
  }

  .checkoutProduct-image {
    display: block;
    margin: auto;
    margin-bottom: 5px;
  }

  .checkoutProduct-info {
    text-align: center;
  }

  .checkoutProduct-info > button {
    margin-bottom: 10px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .all-products {
    max-width: 500px;
  }

  .checkoutProduct {
    display: flex;
    justify-content: left;
  }

  .checkoutProduct-info {
    text-align: center;
    flex: 1 1;
    margin-left: 10px;
    margin-top: auto;
    margin-bottom: auto;
  }
}

.checkoutProduct {
  margin: 15px;
  font-family: "Exo 2", sans-serif;
  /* animation-name: fadeout;
  animation-duration: 1.5s;
  animation-fill-mode: backwards; */
}

.checkoutProduct-title {
  font-size: 20px;
  font-family: "Cinzel Decorative", cursive;
  padding-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 15px;
  /* border-bottom: 1px solid #d3d3d3; */
}

.checkoutProduct-size {
  text-align: center;
  font-size: 20px;
  font-weight: 1000;
  margin-bottom: 5px;
}

.checkoutProduct-price {
  text-align: center;
  font-size: 15px;
  font-weight: 1000;
  margin-bottom: 15px;
}

.checkoutProduct-image {
  object-fit: contain;
  width: 180px;
  height: 180px;
  pointer-events: none;
}

.checkoutProduct-info > button {
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  outline: none;
}

.checkoutProduct-info > button:hover {
  box-shadow: 0px 0px 5px rgb(197, 33, 33);
  border: 1px solid rgb(197, 33, 33);
  cursor: pointer;
  outline: none;
}

.checkoutProduct-info > button:active {
  box-shadow: inset 0px 0px 1px black;
}

.checkoutProduct-info > button:focus {
  outline: none;
}

/* small screen */
@media (max-width: 425px) {
  /* #names {
    display: block;
    margin: auto;
    width: 90%;
  } */

  #first-name {
    margin-bottom: 20px;
  }

  #first-name-input {
    max-width: 90%;
  }

  #last-name-input {
    max-width: 90%;
  }

  #comments {
    display: block;
    text-align: center;
  }
}

/* large screen */
@media (min-width: 426px) {
  #names {
    display: flex;
    text-align: left;
    justify-content: space-between;
    width: 90%;
    padding: 5px;
    margin: auto;
    margin-left: 15px;
    /* border: 1px solid black; */
  }

  #first-name-input {
    margin-left: 19px;
    width: 90%;
  }

  #last-name-input {
    margin-right: 15px;
    width: 90%;
  }

  #email {
    text-align: left;
    /* border: 1px solid black; */
  }

  #email > label,
  #comments > label,
  #required-fname,
  #required-email,
  #required-comments {
    margin-left: 15px;
  }

  #first-name > label {
    margin-left: 19px;
  }

  #comments {
    display: block;
    text-align: left;
    /* border: 1px solid black; */
  }
}

#names {
  margin: 10px;
}

#first-name > input {
  /* border: 1px solid rgb(110, 110, 110); */
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* width: 90%; */
}

#first-name > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#last-name > input {
  /* border: 1px solid rgb(110, 110, 110); */
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  /* width: 90%; */
}

#last-name > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#contact-form > input {
  font-size: 16px;
}

#contact-form > #email {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#contact-form > #first-name {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#contact-form > #last-name {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#contact-form > #comments {
  /* border: 1px solid black; */
  width: 90%;
  padding: 5px;
  margin: auto;
}

#email > input {
  /* border: 1px solid rgb(110, 110, 110); */
  display: block;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  width: 90%;
}

#email > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#comments > textarea {
  /* border: 1px solid rgb(110, 110, 110); */
  display: block;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  width: 90%;
}

#comments > textarea:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#contact {
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  text-align: center;
  margin: 75px auto 0 auto;
  height: 100%;
  max-width: 800px;
  margin-bottom: 15rem;
}

#contact > h1,
h2 {
  font-weight: 100;
}

#contact > p {
  margin: 40px;
}

#contact-form-div {
  margin: 25px;
  margin-top: 50px;
  margin-bottom: 5px;
}

.submit-popup {
  margin-bottom: 90px;
  color: rgb(45, 150, 54);
}

#contact-form {
  display: block;
  max-width: 400px;
  margin: auto;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  border-radius: 5px;
}

#submit {
  max-width: 75px;
  margin: auto;
  margin-bottom: 20px;
}

#submit > button {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(110, 110, 110);
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

#submit > button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

#submit > button:active {
  box-shadow: inset 0px 0px 1px black;
}

#submit > button:focus {
  outline: none;
}

#contact-form > #comments > textarea {
  margin-top: 10px;
  height: 100px;
  resize: none;
}

.styleOnEnter {
  box-shadow: 0px 0px 0px black;
  border: 1px solid rgb(110, 110, 110);
}

.styleBeforeEnter {
  box-shadow: 0px 0px 5px rgb(66, 66, 66);
  border: 1px solid rgb(110, 110, 110);
}

.styleOnError {
  box-shadow: 0px 0px 5px rgb(204, 92, 92);
  border: 1px solid rgb(204, 92, 92);
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-visible {
  visibility: visible;
}

#required {
  color: rgb(204, 92, 92);
}

#contact-me-title {
  font-family: "Satisfy", cursive;
  font-size: min(12vw, 60px);
  text-align: center;
}

.recaptcha-contact {
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
}

/* small screen */
@media (max-width: 767px) {
  .payment {
    display: block;
  }
}

/* large screen */
@media (min-width: 768px) {
  .payment {
    display: flex;
  }
}

.payment {
  margin: auto;
  font-family: "Exo 2", sans-serif;
  max-width: 1200px;
}

.payment-container {
  flex: 1 1;
}

.payment-priceContainer {
  margin: auto;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgray;
}

.payment-couponContainer {
  margin: auto;
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgray;
}

#pay-now-button {
  font-family: "Exo 2", sans-serif;
  margin-top: 15px;
  margin-left: 0px;
}

.not-active-button {
  border: 1px solid rgb(171, 171, 171);
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 15px;
  outline: none;
}

.not-active-button:hover {
  cursor: pointer;
  outline: none;
}

/* #pay-now-button:active {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
} */

.not-active-button:focus {
  outline: none;
}

.active-button {
  border: 1px solid black;
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 15px;
  outline: none;
}

.active-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  border: 1px solid rgb(62, 141, 69);
  font-weight: 1000;
  cursor: pointer;
  outline: none;
}

.active-button:active {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
}

.coupon-button {
  border: 1px solid black;
  box-shadow: 0px 0px 3px black;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-right: 10px;
  padding-left: 10px;
  /* margin-left: 15px;
  margin-right: 15px; */
  font-size: 15px;
  outline: none;
}

.coupon-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  border: 1px solid rgb(62, 141, 69);
  font-weight: 1000;
  cursor: pointer;
  outline: none;
}

.coupon-button:active {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
}

/* end of button styling */

.payment-fullPage > h1 {
  text-align: center;
  background-image: radial-gradient(circle, #f1f3f4, white);
  padding: 10px;
  margin: 0 20px;
  font-weight: 100;
  border-bottom: 1px solid lightgray;
}

.payment-fullPage > h1 > a {
  text-decoration: none;
}

.payment-section {
  display: block;
  padding: 20px;
  margin: 25px 20px;
  background-color: #f1f3f4;
  border: 1px solid lightgray;
}

.shipping-method-section {
  position: relative;
  display: block;
  padding: 20px;
  margin: 25px 20px;
  background-color: #f1f3f4;
  border: 1px solid lightgray;
}

/* .payment-title {
  flex: 0.4;
} */

.payment-items {
  border: 1px solid lightgray;
  padding: 10px;
  margin: 20px auto;
  background-color: white;
}

.payment-email {
  margin: 15px 15px;
}

.payment-email-input,
.shipping-details-input {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid lightgray;
  background-color: white;
  padding: 5px;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  color: black;
  outline: none;
}

.shipping-details-input {
  margin: 5px;
}

.payment-details {
  border: 1px solid lightgray;
  box-sizing: border-box;
  padding: 5px;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  margin: 20px 15px;
  background-color: white;
}

.payment-card,
.shipping-details-full {
  display: flex;
  flex-wrap: wrap;
  font-family: "Exo 2", sans-serif;
  margin: 10px 10px;
}

.payment-card-input {
  border: 1px solid lightgray;
  font-family: "Exo 2", sans-serif;
  box-sizing: border-box;
  background-color: white;
  margin: 5px;
  padding: 5px;
  justify-content: space-between;
}

.coupon-code-input {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid lightgray;
  background-color: white;
  padding: 5px;
  font-size: 20px;
  font-family: "Exo 2", sans-serif;
  color: black;
  outline: none;
}

#card-number {
  width: 213px;
  /* flex: 6; */
}

#card-expiry {
  width: 91px;
  /* flex: 1; */
}

#card-cvc {
  width: 55px;
  /* flex: 1; */
}

.check-box {
  vertical-align: text-bottom;
  width: 20px;
  height: 20px;
  -webkit-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #d3d3d3;
  outline: none;
  background-color: white;
}

.check-box:checked {
  border: 1px solid white;
  background-color: #60b5ee;
}

.label {
  font-size: 20px;
  padding-left: 10px;
  padding-bottom: auto;
}

.checkbox-container {
  display: block;
  padding-right: 15px;
  padding-left: 35px;
  text-indent: -30px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.hidden {
  visibility: hidden;
}

#error-message {
  color: #d73733;
  margin: 0px 15px 15px 15px;
  font-size: 15px;
}

.all-inputs {
  display: inline-flex;
  width: 100%;
}

.all-crosses {
  margin: auto;
  margin-left: 10px;
  max-width: 20px;
  max-height: 20px;
}

.all-crosses > img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.processing {
  font-weight: 1000;
}

h3 {
  text-align: left;
  margin-top: 0px;
}

/* material buttons */

.button-select-options-payment {
  list-style-type: none;
  margin: 15px auto;
  padding: 0;
  box-shadow: rgb(124, 124, 124) 0px 5px 5px -2px;
  border-radius: 5px;
  width: -webkit-max-content;
  width: max-content;
  height: 40px;
  /* outline: 2px solid black; */
}

.button-select-options-payment li {
  float: left;
  width: 100px;
  height: 40px;
  position: relative;
  margin: 0px 0px;
}

.button-select-options-payment label,
.button-select-options-payment input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* box-shadow: rgb(124, 124, 124) 0px 5px 5px -2px; */
}

.button-select-options-payment input[type="radio"] {
  opacity: 0.01;
  z-index: 2;
  width: 100px;
  height: 40px;
  cursor: pointer;
}

.button-select-options-payment input[type="radio"]:hover+label {
  background: #DDD;
}

.button-select-options-payment input[type="radio"]:checked+label,
.Checked+label {
  background: linear-gradient(rgb(0, 145, 255), rgb(94, 184, 252));
  box-shadow: rgba( 241, 242, 244, 100%) 0px 20px 15px 1px
}

.button-select-options-payment label {
  padding: 10px;
  text-align: center;
  border: 1px solid #CCC;
  cursor: pointer;
  z-index: 1;
  background: #FFF;
}

@media (max-width: 375px) {
  .button-select-options-payment {
    display: grid;
    grid-template-columns: 100%;
    /* row-gap: 5px; */
    width: 100px;
    height: 120px;
    margin: 15px auto 25px auto;
  }
  .button-select-options-payment label,
  .button-select-options-payment input:first-child {
    border-radius: 5px 5px 5px 5px;
  }

}

@media (min-width: 376px) {
  .button-select-options-payment li:first-child label {
    border-radius: 5px 0 0 5px;
  }

  .button-select-options-payment li:last-child label {
    border-radius: 0 5px 5px 0;
  }
}

/* SHIPPING OPTIONS COVERS */

.no-shipping-required-cover {
  text-align: center;
  z-index: 5;
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  /* top: inherit;
  left: inherit; */
  /* padding: 20px; */
  /* background-color: rgba(0, 0, 0, 0.535); */
  background-color: #f1f3f48c;
}

.no-shipping-required-cover p {
  color: black;
  margin: 0;
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 0.5rem;
  /* transform: translate(-50%, -50%); */
  /* background-color: rgba(0, 0, 0, 0.337); */
  /* border: 1px solid black; */
  border-radius: 0.5rem;
}

/* small screen */
@media (max-width: 767px) {
  .item-description {
    max-width: 300px;
  }
}

/* large screen */
@media (min-width: 768px) {
  .item-description {
    max-width: 500px;
  }
}

.order {
  font-family: "Exo 2", sans-serif;
  max-width: 1200px;
  margin: auto;
}

.order * {
  text-align: center;
}

.item-description {
  border: 1px solid #dadce0;
  background-color: #f3f3f3;
  margin-left: 15px;
  margin-right: 15px;
  margin: auto;
}

.order-title {
  margin-top: 35px;
  margin-bottom: 10px;
  border-bottom: 1px solid #dadce0;
  margin-right: auto;
  margin-left: auto;
  width: 75px;
}

.order-id {
  margin-bottom: 25px;
}

.order-total {
  padding: 5px;
  border-top: 1px solid #dadce0;
  margin: auto;
}

.orders {
  font-family: "Exo 2", sans-serif;
  max-width: 1200px;
  margin: auto;
  padding: 25px;
}

#returning-customer {
  margin-bottom: 10px;
}

.orders-order {
  margin-bottom: 50px;
}

@media (max-width: 600px) {
  #my-offers {
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
    text-align: center;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  #portraits-collage {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@media (max-width: 1320px) {
  #my-offers {
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    text-align: center;
    height: 100%;
  }
}

#hire-me-page {
  margin: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  max-width: 1200px;
  height: -webkit-max-content;
  height: max-content;
  display: block;
  font-family: "Exo 2", sans-serif;
}

#scroll-message {
  text-align: center;
  margin-top: -55px;
  font-size: 35px;
}

#weddings-collage {
  display: flex;
  object-fit: cover;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.hire-me-section-description {
  /* color: white; */
  font-weight: 1000;
  /* background-color: rgb(79, 79, 79); */
  padding: 10px;
}

#portraits-description,
#events-description,
#wedding-description {
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  /* border: 1px solid black; */
}

/* .photo-hire-me:hover {
  opacity: 100%;
  z-index: 10;
} */

#hire-me-line {
  width: 100%;
  height: 5px;
  background-color: white;
}

#hire-me-title {
  margin: auto;
  font-family: "Satisfy", cursive;
  font-size: min(12vw, 60px);
  text-align: center;
  position: relative;
  margin-top: 0px;
  width: 100%;
  max-width: 1200px;
}

#my-offers {
  margin: auto;
  position: relative;
  text-align: center;
  height: 100%;
  /* width: 100%; */
  max-width: 1200px;
}

.hire-me-title-line {
  width: 100%;
}

.hire-me-section-description {
  font-size: 20px;
}

#paragraph_one {
  margin-top: 4vh;
  font-size: calc((2.5vw + 2.5vh) / 2);
}

#paragraph_two {
  font-size: 15px;
  margin-bottom: 1vh;
  font-size: calc((2vw + 2vh) / 2);
}

#paragraph_three {
  margin: auto;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 4vh;
  margin-bottom: 2vh;
  font-weight: 500;
  font-size: calc((2.5vw + 2.5vh) / 2);
  /* background-color: rgb(255, 255, 255); */
  /* border: 1px solid #d2d2d2; */
  border-radius: 5px;
}

#paragraph_four {
  margin: auto;
  padding: 2px;
  font-size: 15px;
  margin-bottom: 1vh;
  font-weight: 1000;
  font-size: calc((2vw + 2vh) / 2);
  z-index: 10;
}

/* PORTRAIT PHOTOS */

#portraits-collage,
#events-collage,
#wedding-collage {
  margin: auto;
  position: relative;
  text-align: center;
  height: 60%;
  max-width: 1200px;
  margin-top: 5rem;
  /* top: 30vh; */
}

.photo-hire-me {
  margin: auto;
  position: relative;
  padding: 6px;
  max-height: calc((100vw + 60vh) / 6);
  max-width: 250px;
  min-width: 50px;
  min-height: 75px;
  object-fit: cover;
  pointer-events: none;
}

/* END portrait PHOTOS */

/* PORTRAIT TITLE */

#portraits-title,
#events-title,
#wedding-title {
  margin: auto;
  position: relative;
  text-align: center;
  max-width: 1200px;
  width: 100vw;
  margin-top: 10rem;
  /* padding-top: 30vh; */
  font-size: calc((100vw + 60vh) / 40);
  /* background-color: rgba(255, 255, 255, 0.633); */
}

/* END PORTRAIT TITLE */

/* WHITE DIV */

#white-swipe {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  top: 0;
  right: 0;
  position: relative;
  background-color: #f5f5f5;
  z-index: 0;
}

/* END WHITE DIV */

.hire-me-section-title {
  margin-top: 50px;
}

#not-bold {
  font-weight: 500;
  font-size: 20px;
}

.visible {
  opacity: 100%;
}

.invisible {
  opacity: 0%;
}

.landing-page {
  display: block;
  position: relative;
  text-align: center;
  justify-content: center;
  margin: auto;
  width: 100vw;
  height: 100%;
  cursor: default;
  /* background-image: url(https://firebasestorage.googleapis.com/v0/b/alec-hovey-photography.appspot.com/o/images%2FYosemite%20Winter.jpg?alt=media&token=f919d7d9-17e5-435c-878d-d6bf4c0a1557); */
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

/* .landing-page::before {
  content: "";
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background-color: red;
} */

.space-around-text {
  background-color: rgba(255, 255, 255, 0.255);
  width: 100vw;
  height: 100vh;
  padding-top: 35px;
}

#landing-link {
  text-decoration: none;
  color: black;
}

.anywhere {
  margin-top: 35px;
}

#landing-page-info-box {
  text-align: center;
  position: relative;
  top: 10vh;
  margin: auto;
  width: 560px;
  max-width: 80vw;
  padding: 20px;
  background-color: white;
  border: 1px solid #dadce0;
  font-family: "Exo 2", sans-serif;
  border-radius: 5px;
  /* box-shadow: rgb(100, 100, 100) 5px 5px 10px 1px; */
}

#landing-page-info-box h2 {
  font-size: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: rgb(100, 100, 100) 5px 5px 10px 1px;
  border-radius: 10px;
  background-image: linear-gradient(rgb(143, 179, 255), rgb(109, 143, 255));
}

#landing-page-info-box p {
  font-size: 20px;
  line-height: 35px;
}

h1 {
    text-align: center;
}

#login {
    width: -webkit-max-content;
    width: max-content;
    margin: auto;
    margin-top: 5rem;
}

form > input {
    display: block;
    margin-bottom: 1rem;
}

form > label {
    margin-top: 2rem;
}

form > button {
    text-align: center;
    width: 100%;
}
#logged_email {
    text-align: end;
    padding: 1rem;
}

#logged_email > button {
    margin-left: 1rem;
}

#email_admin {
    display: inline-block;
}

#upload_info_body {
    margin: auto;
    margin-top: 5rem;
    text-align: center;
}

#upload_info_options {
    margin-top: 2rem;
    display: block;
}

#upload_info_options > .option_buttons {
    margin: auto;
    margin-top: 2rem;
    display: block;
}

.option_buttons {
    width: -webkit-max-content;
    width: max-content;
}

.button-actual {
    padding: 1rem 2rem 1rem 2rem;
    background-color: lightcoral;
    border-radius: 0.5rem;
    font-size: 1.25rem;
}

.button-actual:hover {
    background-color: coral;
}

.button-actual:focus {
    background-color: cornflowerblue;
}


/* @media (max-width: 1500px) {
    #portrait-package-one {
        flex: 1;
        flex-direction: column;
        margin: 15px;
        max-width: 500px;
      }
} */

#portrait-packages-component {
  font-family: "Exo 2", sans-serif;
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

#portrait-packages-header {
  font-family: "Satisfy", cursive;
  margin-top: 30px;
  margin-bottom: 20px;
}

#portrait-packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

#portrait-package-one {
  flex: 1 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#portrait-package-two {
  flex: 1 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#portrait-package-three {
  flex: 1 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#portrait-package-one > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#portrait-package-two > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#portrait-package-three > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

.solo-package-list {
  list-style: square inside;
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: 500px;
  max-width: 500px;
  min-width: 200px;
}

.solo-package-list > li {
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-button {
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 5px rgb(74, 74, 74);
  background-color: white;
  border-radius: 5px;
  margin: auto;
  position: inherit;
  margin-top: auto;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.select-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.select-button:active {
  box-shadow: inset 0px 0px 1px black;
}

.select-button:focus {
  outline: none;
}

#overview-link {
  margin-top: 25px;
  margin-bottom: 30px;
}

#portrait-request-form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.277);
}

#main-form-div {
  position: relative;
  display: block;
  margin: auto;
  max-width: 400px;
  background: transparent;
  /* border: 1px solid black; */
}

#modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}

#portrait-packages-description {
  font-family: "Exo 2", sans-serif;
  font-size: 1em;
}

#generic-form {
  display: block;
  /* max-width: 400px; */
  max-width: 600px;
  width: 95%;
  margin: auto;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  border-radius: 5px;
}

#generic-form > input {
  font-size: 16px;
}

#first-name-generic {
  margin-top: 10px;
}

#email-input-generic {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#first-name-input-generic {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#last-name-input-generic {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#comment-input-generic {
  margin-top: 10px;
  height: 100px;
  width: 80%;
  resize: none;
  background-color: #ffffff;
  border-radius: 2px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#email-generic > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#first-name-generic > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#last-name-generic > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#comments-generic > textarea:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#generic-submit {
  max-width: 200px;
  margin: auto;
  margin-bottom: 20px;
}

#generic-submit > button {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(110, 110, 110);
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

#generic-submit > button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

#generic-submit > button:active {
  box-shadow: inset 0px 0px 1px black;
}

#generic-submit > button:focus {
  outline: none;
}

.submit-popup {
  margin-bottom: 90px;
  color: rgb(45, 150, 54);
}

.styleOnEnter {
  box-shadow: 0px 0px 0px black;
  border: 1px solid rgb(110, 110, 110);
}

.styleBeforeEnter {
  box-shadow: 0px 0px 5px rgb(66, 66, 66);
  border: 1px solid rgb(110, 110, 110);
}

.styleOnError {
  box-shadow: 0px 0px 5px rgb(204, 92, 92);
  border: 1px solid rgb(204, 92, 92);
}

#required {
  color: rgb(204, 92, 92);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

#package-form-submit {
  width: -webkit-max-content;
  width: max-content;
  padding: 2px;
  margin: auto;
  margin-top: -2rem;
  background-color: #F1F3F4;
}

/* @media (max-width: 1500px) {
    #event-package-one {
        flex: 1;
        flex-direction: column;
        margin: 15px;
        max-width: 500px;
      }
} */

#event-packages-component {
  font-family: "Exo 2", sans-serif;
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

#event-packages-header {
  font-family: "Satisfy", cursive;
  margin-top: 30px;
  margin-bottom: 20px;
}

#event-packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

#event-package-one {
  flex: 1 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#event-package-two {
  flex: 1 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#event-package-three {
  flex: 1 1;
  margin: 15px;
  width: 500px;
  margin-bottom: 25px;
}

#event-package-one > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#event-package-two > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#event-package-three > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

.solo-package-list {
  list-style: square inside;
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: 500px;
  max-width: 500px;
  min-width: 200px;
}

.solo-package-list > li {
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-button {
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 5px rgb(74, 74, 74);
  background-color: white;
  border-radius: 5px;
  margin: auto;
  position: inherit;
  margin-top: auto;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.select-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.select-button:active {
  box-shadow: inset 0px 0px 1px black;
}

.select-button:focus {
  outline: none;
}

#overview-link-event {
  margin-top: 35px;
  margin-bottom: 30px;
}

#portrait-request-form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.277);
}

#main-form-div {
  position: relative;
  display: block;
  margin: auto;
  max-width: 400px;
  background: transparent;
  /* border: 1px solid black; */
}

#modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}

#portrait-packages-description {
  font-family: "Exo 2", sans-serif;
  font-size: 1em;
}

/* @media (max-width: 1500px) {
    #wedding-package-one {
        flex: 1;
        flex-direction: column;
        margin: 15px;
        max-width: 500px;
      }
} */

#wedding-packages-component {
  font-family: "Exo 2", sans-serif;
  text-align: center;
  max-width: 1200px;
  margin: auto;
}

#wedding-packages-header {
  font-family: "Satisfy", cursive;
  margin-top: 30px;
  margin-bottom: 20px;
}

#wedding-packages {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

#wedding-package-one {
  flex: 1 1;
  margin: 15px;
  width: 500px;
}

#wedding-package-two {
  flex: 1 1;
  margin: 15px;
  width: 500px;
}

#wedding-package-three {
  flex: 1 1;
  margin: 15px;
  width: 500px;
}

#wedding-package-one > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#wedding-package-two > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

#wedding-package-three > div {
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  margin-top: 20px;
  margin-bottom: 10px;
  height: 75%;
}

.solo-package-list {
  list-style: square inside;
  padding-right: 30px;
  padding-left: 30px;
  text-align: left;
  padding-top: 30px;
  padding-bottom: 30px;
  max-height: 500px;
  max-width: 500px;
  min-width: 200px;
}

.solo-package-list > li {
  display: list-item;
  list-style-type: circle;
  list-style-position: inside;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 10px;
}

.select-button {
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
  border: 1px solid rgb(110, 110, 110);
  box-shadow: 0px 0px 5px rgb(74, 74, 74);
  background-color: white;
  border-radius: 5px;
  margin: auto;
  position: inherit;
  margin-top: auto;
  width: 100%;
  height: 30px;
  margin-top: 10px;
}

.select-button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

.select-button:active {
  box-shadow: inset 0px 0px 1px black;
}

.select-button:focus {
  outline: none;
}

#overview-link-wedding {
  margin-top: 45px;
  margin-bottom: 30px;
}

#portrait-request-form {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.277);
}

#main-form-div {
  position: relative;
  display: block;
  margin: auto;
  max-width: 400px;
  background: transparent;
  /* border: 1px solid black; */
}

#modal-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
}

#portrait-packages-description {
  font-family: "Exo 2", sans-serif;
  font-size: 1em;
}

.upload-new-div {
    margin: auto;
    text-align: center;
}

#photo {
    opacity: 0;
    /* display: none; */
    max-width: 100px;
}

#photo-preview {
    /* opacity: 0; */
    max-width: 50vw;
}

#connecting_message {
    position: fixed;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.713);
    height: 100vh;
    width: 100vw;
    z-index: 10;
}

#cannot_connect {
    position: fixed;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.713);
    height: 100vh;
    width: 100vw;
    z-index: 20;
}

#uploading_message {
    position: fixed;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.713);
    height: 100vh;
    width: 100vw;
    z-index: 20;
}

#connecting_message > h1 {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: auto;
}

#cannot_connect > div {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: auto;
}

#uploading_message > h1 {
    position: absolute;
    top: 20%;
    width: 100%;
    margin: auto;
}

#submit-photo {
    width: -webkit-max-content;
    width: max-content;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#calculate-aspect {
    width: -webkit-max-content;
    width: max-content;
    margin: auto;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

#submit-photo > input,
#calculate-aspect > input,
#submit-photo > select,
#calculate-aspect > select,
#submit-photo > textarea,
#calculate-aspect > textarea {
    margin: auto;
    margin-bottom: 1rem;
}

#submit-photo > textarea,
#calculate-aspect > textarea {
    width: 15rem;
    height: 5rem;
    resize: none;
}

#are_you_sure_box {
    position: fixed;
    margin: auto;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    width: -webkit-max-content;
    width: max-content;
    padding: 1rem;
    background-color: white;
    border: 1px solid black;
    border-radius: 1rem;
}

#choose-item {
    margin: auto;
    margin-top: 5rem;
    width: 11rem;
    align-items: center;
}

.upload-item-button {
    width: 10rem;
    height: 3rem;
    margin: 1rem 0 1rem 0;
}


