@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

@media (max-width: 600px) {
  #my-offers {
    padding-left: 45px;
    padding-right: 45px;
    position: relative;
    text-align: center;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  #portraits-collage {
    padding-right: 5vw;
    padding-left: 5vw;
  }
}

@media (max-width: 1320px) {
  #my-offers {
    padding-left: 60px;
    padding-right: 60px;
    position: relative;
    text-align: center;
    height: 100%;
  }
}

#hire-me-page {
  margin: auto;
  margin-top: 75px;
  margin-bottom: 75px;
  max-width: 1200px;
  height: max-content;
  display: block;
  font-family: "Exo 2", sans-serif;
}

#scroll-message {
  text-align: center;
  margin-top: -55px;
  font-size: 35px;
}

#weddings-collage {
  display: flex;
  object-fit: cover;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 50px;
}

.hire-me-section-description {
  /* color: white; */
  font-weight: 1000;
  /* background-color: rgb(79, 79, 79); */
  padding: 10px;
}

#portraits-description,
#events-description,
#wedding-description {
  margin: auto;
  position: relative;
  width: 100%;
  max-width: 1200px;
  text-align: center;
  /* border: 1px solid black; */
}

/* .photo-hire-me:hover {
  opacity: 100%;
  z-index: 10;
} */

#hire-me-line {
  width: 100%;
  height: 5px;
  background-color: white;
}

#hire-me-title {
  margin: auto;
  font-family: "Satisfy", cursive;
  font-size: min(12vw, 60px);
  text-align: center;
  position: relative;
  margin-top: 0px;
  width: 100%;
  max-width: 1200px;
}

#my-offers {
  margin: auto;
  position: relative;
  text-align: center;
  height: 100%;
  /* width: 100%; */
  max-width: 1200px;
}

.hire-me-title-line {
  width: 100%;
}

.hire-me-section-description {
  font-size: 20px;
}

#paragraph_one {
  margin-top: 4vh;
  font-size: calc((2.5vw + 2.5vh) / 2);
}

#paragraph_two {
  font-size: 15px;
  margin-bottom: 1vh;
  font-size: calc((2vw + 2vh) / 2);
}

#paragraph_three {
  margin: auto;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 4vh;
  margin-bottom: 2vh;
  font-weight: 500;
  font-size: calc((2.5vw + 2.5vh) / 2);
  /* background-color: rgb(255, 255, 255); */
  /* border: 1px solid #d2d2d2; */
  border-radius: 5px;
}

#paragraph_four {
  margin: auto;
  padding: 2px;
  font-size: 15px;
  margin-bottom: 1vh;
  font-weight: 1000;
  font-size: calc((2vw + 2vh) / 2);
  z-index: 10;
}

/* PORTRAIT PHOTOS */

#portraits-collage,
#events-collage,
#wedding-collage {
  margin: auto;
  position: relative;
  text-align: center;
  height: 60%;
  max-width: 1200px;
  margin-top: 5rem;
  /* top: 30vh; */
}

.photo-hire-me {
  margin: auto;
  position: relative;
  padding: 6px;
  max-height: calc((100vw + 60vh) / 6);
  max-width: 250px;
  min-width: 50px;
  min-height: 75px;
  object-fit: cover;
  pointer-events: none;
}

/* END portrait PHOTOS */

/* PORTRAIT TITLE */

#portraits-title,
#events-title,
#wedding-title {
  margin: auto;
  position: relative;
  text-align: center;
  max-width: 1200px;
  width: 100vw;
  margin-top: 10rem;
  /* padding-top: 30vh; */
  font-size: calc((100vw + 60vh) / 40);
  /* background-color: rgba(255, 255, 255, 0.633); */
}

/* END PORTRAIT TITLE */

/* WHITE DIV */

#white-swipe {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  top: 0;
  right: 0;
  position: relative;
  background-color: #f5f5f5;
  z-index: 0;
}

/* END WHITE DIV */

.hire-me-section-title {
  margin-top: 50px;
}

#not-bold {
  font-weight: 500;
  font-size: 20px;
}

.visible {
  opacity: 100%;
}

.invisible {
  opacity: 0%;
}
