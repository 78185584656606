@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

.orders {
  font-family: "Exo 2", sans-serif;
  max-width: 1200px;
  margin: auto;
  padding: 25px;
}

#returning-customer {
  margin-bottom: 10px;
}

.orders-order {
  margin-bottom: 50px;
}
