@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Cinzel+Decorative:wght@700&family=Exo+2:wght@100&display=swap");

#generic-form {
  display: block;
  /* max-width: 400px; */
  max-width: 600px;
  width: 95%;
  margin: auto;
  padding: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
  border: 1px solid #dadce0;
  background-color: #f1f3f4;
  border-radius: 5px;
}

#generic-form > input {
  font-size: 16px;
}

#first-name-generic {
  margin-top: 10px;
}

#email-input-generic {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#first-name-input-generic {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#last-name-input-generic {
  width: 80%;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 2px;
  height: 20px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#comment-input-generic {
  margin-top: 10px;
  height: 100px;
  width: 80%;
  resize: none;
  background-color: #ffffff;
  border-radius: 2px;
  font-family: "Exo 2", sans-serif;
  font-weight: 1000;
}

#email-generic > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#first-name-generic > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#last-name-generic > input:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#comments-generic > textarea:focus {
  box-shadow: 0px 0px 0px black;
  outline: none;
}

#generic-submit {
  max-width: 200px;
  margin: auto;
  margin-bottom: 20px;
}

#generic-submit > button {
  width: 100%;
  height: 30px;
  border: 1px solid rgb(110, 110, 110);
  margin-top: 10px;
  background-color: #ffffff;
  border-radius: 5px;
}

#generic-submit > button:hover {
  box-shadow: 0px 0px 5px rgb(62, 141, 69);
  cursor: pointer;
  border: none;
}

#generic-submit > button:active {
  box-shadow: inset 0px 0px 1px black;
}

#generic-submit > button:focus {
  outline: none;
}

.submit-popup {
  margin-bottom: 90px;
  color: rgb(45, 150, 54);
}

.styleOnEnter {
  box-shadow: 0px 0px 0px black;
  border: 1px solid rgb(110, 110, 110);
}

.styleBeforeEnter {
  box-shadow: 0px 0px 5px rgb(66, 66, 66);
  border: 1px solid rgb(110, 110, 110);
}

.styleOnError {
  box-shadow: 0px 0px 5px rgb(204, 92, 92);
  border: 1px solid rgb(204, 92, 92);
}

#required {
  color: rgb(204, 92, 92);
}

.visible {
  visibility: visible;
}

.invisible {
  visibility: hidden;
}

#package-form-submit {
  width: max-content;
  padding: 2px;
  margin: auto;
  margin-top: -2rem;
  background-color: #F1F3F4;
}
